import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAuth {
    lookupService = new APIServiceLookUp();

    async userLogin(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/Login`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateUsername(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/updateUsername`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateEmail(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/updateEmail`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updatePassword(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/updatePassword`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deactiveUser(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/deactiveUser`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}