import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../../config/atoms';

import { LayoutDashboard, Notebook, SquareUser, Settings, LogOut, Image } from 'lucide-react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const { page } = useParams();

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const navigate = useNavigate();

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const getOverview = () => {
        if (page === "main" || page === "portal" || page === "research" || page === "publication" || page === "researchAdd" || page === "store" || page === "news" || page === "directory" || page === "blogs" || page === "archives" || page === "pageStyle") {
            return true;
        }
        return false;
    }

    const getEnroll = () => {
        if (page === "contact" || page === "newsletter" || page === "event" || page === "course" || page === "donation" || page === "corporate") {
            return true;
        }
        return false;
    }

    const getMedia = () => {
        if (page === "media" || page === "clients") {
            return true;
        }
        return false;
    }

    const getStaff = () => {
        if (page === "staff" || page === "staffAdd") {
            return true;
        }
        return false;
    }

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
    }

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-prime hidden xs:block">
                <span className="cursor-pointer" onClick={() => { changeMainMenu('main') }}>
                    <div className="w-16 h-16">
                        <img src={icLogo} className="w-12 m-auto py-2 brdImg" />
                    </div>
                </span>
                <div className='w-3/5 h-[2px] bg-white rounded-full mx-[20%] my-1'></div>
                <ul className="flex flex-col items-center h-[90%]">
                    <Tippy content="Overview Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getOverview() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('main') }}>
                            <LayoutDashboard size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Users Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getEnroll() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('user') }}>
                            <Notebook size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Media Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getMedia() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('media') }}>
                            <Image size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Ad Managment" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "ad" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('ad') }}>
                            <Image size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Staff Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getStaff() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('staff') }}>
                            <SquareUser size={20} />
                        </li>
                    </Tippy>
                    <div className='flex-grow'></div>
                    <Tippy content="Settings" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "setting" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('setting') }}>
                            <Settings size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Logout" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white text-white hover:text-ascent`} onClick={() => { logout() }}>
                            <LogOut size={20} />
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    )
}
