import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import PanelDev from 'panels/dev/PanelDev';

import PanelMedia from 'panels/media/PanelMedia';
import PanelClient from 'panels/media/PanelClient';

export default function LayoutMedia() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'media':
                                return (<PanelMedia />);
                            case 'clients':
                                return (<PanelClient />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
