import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutHome from 'layouts/LayoutHome';
import LayoutEnrollment from 'layouts/LayoutEnrollment';
import LayoutInfo from 'layouts/LayoutInfo';
import LayoutSetting from 'layouts/LayoutSetting';
import LayoutStaff from 'layouts/LayoutStaff';
import LayoutMedia from 'layouts/LayoutMedia';

export default function PageHome() {

    const { page } = useParams();

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {(() => {
                switch (page) {
                    case 'main':
                        return (<LayoutHome />);
                    case 'user':
                    case 'event':
                    case 'corporate':
                        return (<LayoutEnrollment />);
                    case 'userInfo':
                        return (<LayoutInfo />);
                    case 'media':
                    case 'clients':
                        return (<LayoutMedia />);
                    case 'staff':
                    case 'staffAdd':
                        return (<LayoutStaff />);
                    case 'setting':
                        return (<LayoutSetting />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                No Data Found
                            </div>
                        );
                }
            })()}
        </div>
    )
}
