import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import TopBar from '../../core/widgets/ui/TopBar';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import axios from 'axios';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import ServiceMedia from '../../services/serviceMedia';
import ServiceUtility from '../../services/serviceUtility';

import { API_URL } from '../../services/models/AppConstent';
import APIResponse from '../../services/models/APIResponse';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import animPage from '../../assets/anim/anim-pageload.json';

import fileIcon from '../../assets/image/folder.png';
import videoIcon from '../../assets/image/clapperboard.png';
import { CloudUpload } from 'lucide-react';

const allowedExtensions = ["png", "jpeg", 'mp4'];

export default function PanelMedia() {
    const [token] = useRecoilState(tokenAtom);

    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState([]);

    const [folder, setFolder] = useState({});

    const [imageIndex, setImageIndex] = useState(-1);

    const [isView, setIsView] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);

    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);

    const mediaService = new ServiceMedia();
    const utilService = new ServiceUtility();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            title: '',
            disc: '',
        },
        validationSchema: formVSchema,
        enableReinitialize: true,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "title": values.title
            }
            mediaService.setMedia(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    resetForm();
                    setIsOpen(false);
                    loadData();
                    toast.success("New Collection Added", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    toast.error("Server error, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        }
    });

    const handleFileChange = (event) => {
        let TempFiles = [...files];
        let TempProgs = [...progress];
        const selectedFiles = event.target.files;

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const fileExtension = selectedFiles[i]?.type.split("/")[1];

            if (!allowedExtensions.includes(fileExtension)) {
                toast.error("Only .png, .jpeg, .mp4 file format is allowed, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                TempProgs.push(0);
                TempFiles.push(file);
            }
        }

        setProgress(TempProgs);
        setFiles(TempFiles);
    };

    const removeSelectedFile = (index) => {
        let TempFiles = [...files];
        TempFiles.splice(index, 1);
        setFiles(TempFiles);
    }

    const uploadFiles = () => {
        if (files.length !== 0) {
            setSubmit(true);
            const uploadPromises = files.map(uploadFile);
            Promise.all(uploadPromises).then((ress) => {
                let filesData = ress.map((re) => {
                    return re.data;
                });

                folder.files.map((fils) => {
                    filesData.push(fils);
                });

                let body = {
                    "mid": folder._id,
                    "data": {
                        "files": filesData
                    },
                };

                mediaService.updateMedia(body, token).then((res) => {
                    setSubmit(false);
                    setIsUpload(false);
                    setFiles([]);
                    setProgress([]);
                    setFolder({});
                    toast.success(`${ress.length} Files Uploaded`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    loadData();
                });
            });
        } else {
            toast.error("Please select atleast one file for upload!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const uploadFile = async (file, index) => {
        var formDataA = new FormData();
        formDataA.append("path", 'media');
        formDataA.append("doc", file);

        var url = `${API_URL}file/uploadFile`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };

        const response = await axios.post(url, formDataA, {
            headers: requestHeaders,
            onUploadProgress: (progressEvent) => {
                const fileProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setProgress((prevProgress) => {
                    const updatedProgress = [...prevProgress];
                    updatedProgress[index] = fileProgress;
                    return updatedProgress;
                });
            },
        });

        var main = response.data;
        return new APIResponse(
            main["data"],
            main["status"],
            main["statuscode"],
            main["msg"],
        );
    }

    const deleteFile = (fileurl, index) => {
        setSubmit(true);
        let bodyA = { "path": fileurl }
        utilService.deleteFile(bodyA, token).then((res) => {
            let filesData = [...folder.files];
            filesData.splice(index, 1);
            let body = {
                "mid": folder._id,
                "data": {
                    "files": filesData
                },
            };
            mediaService.updateMedia(body, token).then((res) => {
                setSubmit(false);
                setFolder(res.data);
                toast.success(`1 File Deleted`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            });
        });
    }

    const deleteFolder = () => {
        if (folder.files.length == 0) {
            let body = {
                "fid": folder._id
            }
            mediaService.delMedia(body, token).then((res) => {
                if (res.status) {
                    toast.success("Folder deleted", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    toast.error("Server error, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setIsView(false);
                loadData();
            });
        } else {
            toast.error(`Delete all the files first.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const handleZoomChange = (shouldZoom, selIndex) => {
        if (shouldZoom) {
            setImageIndex(selIndex);
            setIsZoomed(shouldZoom);
        } else {
            setImageIndex(-1);
            setIsZoomed(shouldZoom);
        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {};
            var doc = await mediaService.getAllMedia(body, token);
            setFolders(doc.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Dialog open={isOpen} onClose={() => {
                setIsOpen(false);
                resetForm();
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add Folder &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                            resetForm();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Folder Name<sup className="text-red-600">*</sup></label>
                            <input type="text" id="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                            {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                        </div>
                        <div className='flex items-end justify-end'>
                            <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "ADD"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {
                isView && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] absolute right-0 h-screen p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                {
                                    isProcessing && <>
                                        <div className='flex items-center justify-between text-2xl'>
                                            <h1 className='font-sans font-medium text-gray-900'>Folder - {folder.title}</h1>
                                            <div className='flex items-center'>
                                                <div className='w-[36px] h-[36px] bg-red-100 flex items-center justify-center rounded-full' onClick={() => {
                                                    deleteFolder();
                                                }}>
                                                    <i className="las la-trash text-lg text-red-600 cursor-pointer"></i>
                                                </div>
                                                <span onClick={() => {
                                                    setIsView(false);
                                                    setFolder({});
                                                    loadData();
                                                }} className='ml-3'><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                            </div>
                                        </div>
                                        <hr className='my-4' />
                                        <div className="mb-4">
                                            {
                                                folder.files.map((file, index) => {
                                                    return <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className='flex items-center justify-between'>
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <ControlledZoom isZoomed={isZoomed} onZoomChange={(shouldZoom) => { handleZoomChange(shouldZoom, index); }}>
                                                                    {file?.ext !== "mp4" && <img src={file.fileurl} alt="Icon Template" className={`w-10 h-10 ${isZoomed ? imageIndex === index ? '' : 'hidden' : ''}`} />}
                                                                    {file?.ext === "mp4" && <img src={videoIcon} alt="Icon Template" className={`w-10 h-10 ${isZoomed ? imageIndex === index ? '' : 'hidden' : ''}`} />}
                                                                </ControlledZoom>
                                                                <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                                                    {file.name}&nbsp;
                                                                    <i className="las la-link text-gray-400 text-lg"></i>
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono" onClick={() => {
                                                                    setIsZoomed(true);
                                                                }}>
                                                                    <span>View</span>
                                                                </label>&nbsp;&nbsp;
                                                                <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                    deleteFile(file.fileurl, index);
                                                                }}>
                                                                    <span>Delete</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                            {
                                                folder.files.length === 0 && <div>
                                                    <div>No media found in folder.</div>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    !isProcessing && <>
                                        <div className='flex items-center justify-center w-full h-full'>
                                            <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                                        </div>
                                    </>
                                }
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            {
                isUpload && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] absolute right-0 h-screen p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                {
                                    isProcessing && <>
                                        <div className='flex items-center justify-between text-2xl'>
                                            <h1 className='font-sans font-medium text-gray-900'>Folder - {folder.title}</h1>
                                            <span onClick={() => {
                                                setIsUpload(false);
                                                setFolder({});
                                                setFiles([]);
                                                loadData();
                                            }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                        </div>
                                        <hr className='my-4' />
                                        <div className="mb-4">
                                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                <div className="space-y-1 text-center">
                                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                            <span>Upload a file</span>
                                                            <input type="file" className="sr-only" multiple={true} onChange={handleFileChange} />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-gray-500 text-center">
                                                        PNG, JPG up to 12 MB<br />
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                files.map((file, index) => {
                                                    return <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                        <div className='flex items-center justify-between'>
                                                            <div className="w-0 flex-1 flex items-center">
                                                                {file?.type.split("/")[1] !== "mp4" && <img src={URL.createObjectURL(file)} alt="Icon Template" className='w-10 h-10' />}
                                                                {file?.type.split("/")[1] === "mp4" && <img src={videoIcon} alt="Icon Template" className='w-10 h-10' />}
                                                                <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                                                    {file.name}&nbsp;
                                                                    <i className="las la-link text-gray-400 text-lg"></i>
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                    removeSelectedFile(index);
                                                                }}>
                                                                    <span>Remove</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            progress[index] !== 0 && <div className='w-full bg-gray-300 mt-1' style={{ height: '4px' }}>
                                                                <div style={{ width: `${progress[index]}%`, transitionDuration: `4s` }} className="h-full transition-all bg-green-400"></div>
                                                            </div>
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='w-full'>
                                            <button type="button" className="w-full text-white bg-primeLight hover:bg-prime focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center" disabled={submit} onClick={() => {
                                                uploadFiles();
                                            }}>
                                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                {!submit && "UPLOAD"}
                                            </button>
                                        </div>
                                    </>
                                }
                                {
                                    !isProcessing && <>
                                        <div className='flex items-center justify-center w-full h-full'>
                                            <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                                        </div>
                                    </>
                                }
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            {
                !loading && <ScrollArea>
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10">
                                <h2 class="text-2xl font-medium truncate ml-2">
                                    Media Center
                                </h2>
                                <div className='flex z-50'>
                                    <button className="py-1 px-3 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4" onClick={() => {
                                        setIsOpen(true);
                                    }}><i className="las la-plus mr-2"></i> Add Folder</button>
                                    <TopBar />
                                </div>
                            </div>
                        </div>
                        <hr className='mt-4 col-span-12' />
                        {
                            folders.length === 0 && <div className="intro-y mt-6 col-span-12 flex items-center justify-center">
                                <p>No Data Found.</p>
                            </div>
                        }
                        <div className='col-span-12 grid grid-cols-6 gap-4'>
                            {
                                folders.length !== 0 && folders.map((folder) => {
                                    return <div className='intro-y col-span-1 p-2 border rounded-md my-4 relative' key={folder._id}>
                                        <div className='flex items-center'>
                                            <img src={fileIcon} alt="item" className='w-[54px] cursor-pointer' onClick={() => {
                                                setFolder(folder);
                                                setIsView(true);
                                            }} />
                                            <div className='ml-4'>
                                                <p className='text-sm font-medium w-full'>{folder.title}</p>
                                                <p className='text-xs font-medium w-full'>({folder.files.length}) Items</p>
                                            </div>
                                            <div className='flex-grow'></div>
                                            <div className='w-[48px] h-[48px] text-gray-600 hover:bg-gray-100 cursor-pointer flex items-center justify-center rounded-full' onClick={() => {
                                                setFolder(folder);
                                                setIsUpload(true);
                                            }}>
                                                <CloudUpload />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </ScrollArea>
            }
        </div>
    )
}
