import axios from 'axios';

import APIServiceLookUp from './serviceAPI';

import { API_URL } from './models/AppConstent';
import APIResponse from './models/APIResponse';

export default class ServiceUtility {
    lookupService = new APIServiceLookUp();

    async getTokenById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}utility/getTokenById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async verifyToken(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}utility/verifyToken`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async uploadFile(body, token) {
        var url = `${API_URL}file/uploadFile`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        var response = await axios.post(url, body, { headers: requestHeaders });
        var main = response.data;
        return new APIResponse(
            main["data"],
            main["status"],
            main["statuscode"],
            main["msg"],
        );
    }

    async replaceFile(body, token) {
        var url = `${API_URL}file/replaceFile`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        var response = await axios.post(url, body, { headers: requestHeaders });
        var main = response.data;
        return new APIResponse(
            main["data"],
            main["status"],
            main["statuscode"],
            main["msg"],
        );
    }

    async deleteFile(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}file/deleteFile`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}