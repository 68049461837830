import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import PanelDev from 'panels/dev/PanelDev';

import PanelContact from 'panels/enrollment/PanelContact';
import PanelNewsletter from 'panels/enrollment/PanelNewsletter';
import PanelEvent from 'panels/enrollment/PanelEvent';
import PanelCourse from 'panels/enrollment/PanelCourse';
import PanelDonation from 'panels/enrollment/PanelDonation';
import PanelCorporate from 'panels/enrollment/PanelCorporate';

export default function LayoutEnrollment() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-xl lg:text-2xl font-medium pt-4 ml-6">Managment Menu</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'user' ? 'text-prime font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('user') }}>Users</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'event' ? 'text-prime font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('event') }}>Event</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'corporate' ? 'text-prime font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('corporate') }}>Corporate Programs</li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'user':
                                return (<PanelContact onToggle={toggleMenu} />);
                            case 'event':
                                return (<PanelEvent onToggle={toggleMenu} />);
                            case 'corporate':
                            // return (<PanelCorporate onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
