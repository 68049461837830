import React from 'react';
import Lottie from 'lottie-react';

import animationData from '../assets/anim/anime-reponsive.json';

export default function PageResponsive() {
    return (
        <div className='w-full h-screen overflow-hidden flex flex-col items-center justify-center p-4 text-center'>
            <div className='w-80'>
                <Lottie animationData={animationData} />
            </div>
            <p className='mt-20 font-bold text-2xl'>Site best viewed at 1024 x 768 resolution in Internet Explorer 10+, Google Chrome 49+, Firefox 45+ and Safari 6+</p>
        </div>
    );
}
