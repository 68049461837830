import React from 'react'

export default function LayoutInfo() {
    return (
        <>
            <div className='h-screen w-full'>
                <iframe src={"https://app.notjustanalytics.com/it/analysis/jst_brightnesss"} title="Embedded Page" width="100%" height="100%" style={{ border: 'none' }} allowFullScreen>
                </iframe>
            </div>
        </>
    )
}
