import React from 'react';
import { useParams } from 'react-router-dom';

import PanelDev from '../panels/dev/PanelDev';

import PanelSettings from '../panels/settings/PanelSettings';

export default function LayoutSetting() {

    const { page } = useParams();

    return (
        <>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'setting':
                                return (<PanelSettings />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
