import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import PanelDev from 'panels/dev/PanelDev';

import PanelOverview from 'panels/home/PanelOverview';
import PanelPortal from 'panels/cms/PanelPortal';
import PanelResearch from 'panels/cms/PanelResearch';
import PanelResearchAdd from 'panels/cms/PanelResearchAdd';
import PanelPublication from 'panels/cms/PanelPublication';
import PanelStore from 'panels/cms/PanelStore';
import PanelNews from 'panels/cms/PanelNews';
import PanelDirectory from 'panels/cms/PanelDirectory';
import PanelBlogs from 'panels/cms/PanelBlogs';
import PanelArchives from 'panels/cms/PanelArchives';
import PanelResearchStyle from 'panels/cms/PanelResearchStyle';

export default function LayoutContact() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'main':
                                return (<PanelOverview />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
