import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import ServiceMedia from '../../services/serviceMedia';
import ServiceUtility from '../../services/serviceUtility';

import { API_URL } from '../../services/models/AppConstent';

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import animPage from '../../assets/anim/anim-pageload.json';

import videoIcon from '../../assets/image/clapperboard.png';
import { Trash } from 'lucide-react';

const allowedExtensions = ["png", "jpeg", 'mp4'];

export default function PanelClient() {
    const [token] = useRecoilState(tokenAtom);

    const [files, setFiles] = useState([]);

    const [upfiles, setUpFiles] = useState(null);

    const [progress, setProgress] = useState(0);
    const [imageIndex, setImageIndex] = useState(-1);

    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const [isZoomed, setIsZoomed] = useState(false);

    const mediaService = new ServiceMedia();
    const utilService = new ServiceUtility();

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const fileExtension = selectedFiles[i]?.type.split("/")[1];

            if (!allowedExtensions.includes(fileExtension)) {
                toast.error("Only .png, .jpeg, .mp4 file format is allowed, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                setProgress(0);
                setUpFiles(file);
            }
        }
    };

    const uploadFiles = () => {
        if (files !== null) {
            setSubmit(true);
            var formDataA = new FormData();
            formDataA.append("path", 'clients');
            formDataA.append("doc", upfiles);

            var url = `${API_URL}file/uploadFile`;
            var requestHeaders = {
                'Content-type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `bearer ${token}`,
            };

            axios.post(url, formDataA, {
                headers: requestHeaders,
                onUploadProgress: (progressEvent) => {
                    const fileProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress((prevProgress) => {
                        return fileProgress;
                    });
                },
            }).then((response) => {
                let body = {
                    "media": response.data.data
                };
                mediaService.setClient(body, token).then((res) => {
                    setSubmit(false);
                    setIsOpen(false);
                    setUpFiles(null);
                    setProgress(0);
                    toast.success(`File Uploaded`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    loadData();
                });
            });
        } else {
            toast.error("Please select one file for upload!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const handleZoomChange = (shouldZoom, selIndex) => {
        if (shouldZoom) {
            setImageIndex(selIndex);
            setIsZoomed(shouldZoom);
        } else {
            setImageIndex(-1);
            setIsZoomed(shouldZoom);
        }
    }

    const deleteFile = (fileurl, id) => {
        setSubmit(true);
        let bodyA = { "path": fileurl }
        utilService.deleteFile(bodyA, token).then((res) => {
            let body = {
                "cid": id,
            };
            mediaService.delClient(body, token).then((res) => {
                toast.success(`1 File Deleted`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                setSubmit(false);
                setUpFiles(null);
                loadData();
            });
        });
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {};
            var doc = await mediaService.getAllClient(body, token);
            setFiles(doc.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                isOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] absolute right-0 h-screen p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                {
                                    isProcessing && <>
                                        <div className='flex items-center justify-between text-2xl'>
                                            <h1 className='font-sans font-medium text-gray-900'>Upload logo</h1>
                                            <span onClick={() => {
                                                setIsOpen(false);
                                                setUpFiles(null);
                                                loadData();
                                            }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                        </div>
                                        <hr className='my-4' />
                                        <div className="mb-4">
                                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                <div className="space-y-1 text-center">
                                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                            <span>Upload a file</span>
                                                            <input type="file" className="sr-only" multiple={true} onChange={handleFileChange} />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-gray-500 text-center">
                                                        PNG, JPG up to 12 MB<br />
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                upfiles !== null && <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                    <div className='flex items-center justify-between'>
                                                        <div className="w-0 flex-1 flex items-center">
                                                            {upfiles?.type.split("/")[1] !== "mp4" && <img src={URL.createObjectURL(upfiles)} alt="Icon Template" className='w-10 h-10' />}
                                                            {upfiles?.type.split("/")[1] === "mp4" && <img src={videoIcon} alt="Icon Template" className='w-10 h-10' />}
                                                            <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                                                {upfiles.name}&nbsp;
                                                                <i className="las la-link text-gray-400 text-lg"></i>
                                                            </span>
                                                        </div>
                                                        <div className="ml-4 flex-shrink-0">
                                                            <label className="relative cursor-pointer rounded-md font-medium text-red-600 font-mono" onClick={() => {
                                                                setUpFiles(null)
                                                            }}>
                                                                <span>Remove</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        progress !== 0 && <div className='w-full bg-gray-300 mt-1' style={{ height: '4px' }}>
                                                            <div style={{ width: `${progress}%`, transitionDuration: `4s` }} className="h-full transition-all bg-green-400"></div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className='w-full'>
                                            <button type="button" className="w-full text-white bg-primeLight hover:bg-prime focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center" disabled={submit} onClick={() => {
                                                uploadFiles();
                                            }}>
                                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                {!submit && "UPLOAD"}
                                            </button>
                                        </div>
                                    </>
                                }
                                {
                                    !isProcessing && <>
                                        <div className='flex items-center justify-center w-full h-full'>
                                            <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                                        </div>
                                    </>
                                }
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            {
                !loading && <ScrollArea>
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10">
                                <h2 class="text-2xl font-medium truncate ml-2">
                                    Client Logos
                                </h2>
                                <div className='flex z-50'>
                                    <button className="py-1 px-3 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4" onClick={() => {
                                        setIsOpen(true);
                                    }}><i className="las la-plus mr-2"></i> Add Logo</button>
                                    <TopBar />
                                </div>
                            </div>
                        </div>
                        <hr className='mt-4 col-span-12' />
                        {
                            files.length === 0 && <div className="intro-y mt-6 col-span-12 flex items-center justify-center">
                                <p>No Data Found.</p>
                            </div>
                        }
                        <div className='col-span-12 grid grid-cols-6 gap-4'>
                            {
                                files.length !== 0 && files.map((fil, index) => {
                                    return <div className='intro-y col-span-1 border rounded-md my-4' key={fil._id}>
                                        <div className='w-[28px] h-[28px] absolute bottom-[6px] left-[6px] z-50 bg-red-100 text-red-800 flex items-center justify-center rounded-full' onClick={() => { deleteFile(fil.media.fileurl, fil._id) }}>
                                            <Trash size={18} className="" />
                                        </div>
                                        <ControlledZoom isZoomed={isZoomed} onZoomChange={(shouldZoom) => { handleZoomChange(shouldZoom, index); }}>
                                            <img src={fil.media.fileurl} alt="item" className='cursor-pointer rounded-md' />
                                        </ControlledZoom>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </ScrollArea>
            }
        </div>
    )
}
