import React, { useState, useRef, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom, tokenAtom } from '../core/config/atoms';

import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';

import bgLogin from '../assets/image/bglogin.png';

export default function PageAuth() {

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [, setUserData] = useRecoilState(userAtom);
    const [token, setToken] = useRecoilState(tokenAtom);

    const [submit, setSubmit] = useState(false);

    const emailRef = useRef('');
    const passwordRef = useRef('');

    const navigate = useNavigate();
    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);

        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            if (password === "" || password === undefined) {
                toast.error('Password should not be empty !', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                setSubmit(false);
            } else {
                var body = {
                    email: email,
                    password: password
                }
                authService.userLogin(body, '').then((d) => {
                    if (d.status) {
                        setUserData(d.data);
                        utilService.getTokenById({ id: d.data._id }, '').then((res) => {
                            setToken(res.data.token);
                            setAuthStatus('verified');
                            navigate('/home/main');
                            setSubmit(false);
                        });
                    } else {
                        toast.error('Error: ' + d.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        setSubmit(false);
                    }
                });
            }
        } else {
            toast.error('Enter valid email id !', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            setSubmit(false);
        }
    }

    useEffect(() => {
        if (authStatus === 'verified') {
            utilService.verifyToken({ 'token': token }, '').then((res) => {
                if (res.status) {
                    navigate('/home/main');
                }
            });
        }
    }, [authStatus, navigate]);

    return (
        <>
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center justify-center xl:bg-blue-50">
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 py-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <h1 className="text-2xl text-black font-serif font-medium">Welcome back!</h1>
                                <h2 className="text-sm text-gray-400 font-serif">We`re so excited to see you again!</h2>
                                <form className="mt-8" onSubmit={handleSubmit}>
                                    <div className="flex flex-col">
                                        <label className="text-sm text-gray-900 font-serif">Email ID
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <input type="email" placeholder="i.e. jhon.deo@gmail.com" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" ref={emailRef} />
                                    </div>
                                    <div className="flex flex-col mt-4">
                                        <label className="text-sm text-gray-900 font-serif">Password
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <input type="password" placeholder="i.e. xxxxxxxx" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" ref={passwordRef} />
                                    </div>
                                    <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                        {!submit && "SUBMIT"}
                                    </button>
                                    <p className='text-sm mt-4 text-center w-[240px] mx-auto'>By clicking continue, you agree to our <b><u>Terms of Service</u></b> and <b><u>Privacy Policy</u></b>.</p>
                                </form>
                                <div id="captcha"></div>
                                <div className="flex-grow"></div>
                                <p className="text-sm text-gray-400 font-serif text-center mt-16">&copy; 2024 BEATRONIX.</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center">
                        <div className="w-full h-full flex items-start lg:items-center justify-center">
                            <img src={bgLogin} alt="brand logo" className="login background" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}